import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"

const Brand = () => {
  const imageRender = data => {
    const { fluid } = data.placeholderImage.childImageSharp

    return <Image fluid={fluid} />
  }

  return (
    <StaticQuery
      query={graphql`
        query {
          placeholderImage: file(relativePath: { eq: "navbar_icon.png" }) {
            childImageSharp {
              fluid(maxWidth: 80) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      `}
      render={imageRender}
    />
  )
}

export default Brand
