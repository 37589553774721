import styled from "styled-components"

const ImageContainer = styled.div`
  min-width: 200px;
  width: 200px;
  height: 200px;
  margin-right: 42px;
  border: 5px solid white;
  border-radius: 50%;

  img {
    border-radius: 50%;
  }

  ${({ theme }) => `
    @media (max-width: ${theme.breakpoints.mobile}px) {
      margin-right: 0px;
    }
  `}
`

export default ImageContainer
