import React, { Fragment } from "react"
import PropTypes from "prop-types"
import { StyledProvider, BackToTop } from "components-extra"

import { theme } from "styles"
import { GlobalContext, globalValues } from "providers"

import "./styles/global.css"

const Layout = ({ children }) => {
  return (
    <StyledProvider theme={theme}>
      <GlobalContext.Provider value={globalValues}>
        <Fragment>
          {children}
          <BackToTop />
        </Fragment>
      </GlobalContext.Provider>
    </StyledProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  hideMenu: PropTypes.bool,
}

export default Layout
