import styled from "styled-components"

const ImageContainer = styled.div`
  margin: 80px auto;
  width: 350px;
  ${({ theme }) => `
    box-shadow: ${theme.customShadows.image.default};

    @media (max-width: ${theme.breakpoints.mobile}px) {
      width: 100%;
    }
  `}
`

export default ImageContainer
