import styled from "styled-components"

const Container = styled.div`
  margin: 0px auto;
  max-width: 700px;

  ${({ theme }) => `
    @media (max-width: ${theme.breakpoints.tablet}px) {
      max-width: 600px;
    }

    @media (max-width: ${theme.breakpoints.mobile}px) {
      margin: 50px auto;
    }

  `}
`

export default Container
