import { Link } from "gatsby"
import React from "react"

import Background from "./components/Background"
import Navbar from "../Navbar"

import Subtitle from "./styles/Subtitle"
import BigSubtitle from "./styles/BigSubtitle"
import Title from "./styles/Title"
import TitlesContainer from "./styles/TitlesContainer"

import BookPreview from "../BookPreview"

const Header = () => {
  return (
    <Background>
      <Navbar />
      <TitlesContainer>
        <Title>
          <Link to="/">AVEC VOUS DEMAIN</Link>
        </Title>
        <Subtitle>
          Un livre de Georges Paumard, aux éditions Sydney Laurent
        </Subtitle>
        <BookPreview />
        <BigSubtitle>
          De maintenant à 2050, découvrez les clés de votre avenir
        </BigSubtitle>
      </TitlesContainer>
    </Background>
  )
}

export default Header
