import styled from "styled-components"

const BigSubtitle = styled.h1`
  ${({ theme }) => `
      color: ${theme.colors.title};
      font-size: ${theme.fontSizes.bigsubtitle};
  `}
`

export default BigSubtitle
