import { css } from "styled-components"
import PropTypes from "prop-types"

const margins = css`
  ${({ mt }) => mt && `margin-top: ${mt}px`};
  ${({ mr }) => mr && `margin-right: ${mr}px`};
  ${({ mb }) => mb && `margin-bottom: ${mb}px`};
  ${({ ml }) => ml && `margin-left: ${ml}px`};
`

margins.propTypes = {
  mt: PropTypes.string,
  mr: PropTypes.string,
  mb: PropTypes.string,
  ml: PropTypes.string,
}

export default margins
