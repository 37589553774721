import React, { PureComponent } from "react"

import About from "./About"
import Order from "./Order"
import Presentation from "./Presentation"
import Samples from "./Samples"

import { Container, SectionsContainer } from "./styles"

class Sections extends PureComponent {
  render() {
    return (
      <SectionsContainer>
        <Container>
          <Presentation />
          <Samples />
          <About />
          <Order />
        </Container>
      </SectionsContainer>
    )
  }
}

export default Sections
