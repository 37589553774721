import React from "react"

export const globalValues = {
  links: {
    amazon:
      "https://www.amazon.fr/Avec-Vous-Demain-Georges-Paumard/dp/B07HSK1LWJ",
    ouestFrance:
      "https://www.ouest-france.fr/pays-de-la-loire/la-baule-44500/l-avenir-de-la-baule-au-coeur-d-un-roman-6024863",
    rakuten:
      "https://fr.shopping.rakuten.com/offer/buy/3480274388/avec-vous-demain-format-beau-livre.html",
    fnac: "https://livre.fnac.com/a12948546/Georges-Paumard-Avec-vous-demain",
    lib:
      "https://www.librairiedialogues.fr/livre/14880527-avec-vous-demain-georges-paumard-sydney-laurent",
    chapitre:
      "https://www.chapitre.com/BOOK/paumard-georges/avec-vous-demain,78738707.aspx",
    decitre:
      "https://www.decitre.fr/livres/avec-vous-demain-9791032612002.html",
    libraires:
      "https://www.placedeslibraires.fr/livre/9791032612002-avec-vous-demain-georges-paumard/",
  },
  contributors: {
    alelain: "//github.com/alexandre-lelain",
    componentsExtra: "https://github.com/alexandre-lelain/components-extra",
  },
}

const GlobalContext = React.createContext(globalValues)

export default GlobalContext
