import React, { PureComponent } from "react"
import { StaticQuery, graphql } from "gatsby"

import resellerRender from "./Reseller"

class Lib extends PureComponent {
  render() {
    return (
      <StaticQuery
        query={graphql`
          query {
            placeholderImage: file(relativePath: { eq: "lib.png" }) {
              childImageSharp {
                fluid(quality: 99, maxWidth: 300) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        `}
        render={data => resellerRender("Sur Librairies Dialogues", "lib", data)}
      />
    )
  }
}

export default Lib
