import styled from "styled-components"

const TitlesContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  text-align: center;
  word-wrap: break-word;
  padding: 24px;
`

export default TitlesContainer
