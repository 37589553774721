import styled from "styled-components"

const Link = styled.a`
  ${({ dark, theme }) => `
    text-decoration: none;
    color: ${dark ? theme.colors.darkPrimary : theme.colors.cadetblue};
  `}
`

export default Link
