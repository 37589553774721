import styled from "styled-components"
import { Paragraph } from "components-extra"

import { margins, paddings } from "styles"

const StyledParagraph = styled(Paragraph)`
  ${({ theme }) => `
    color: ${theme.colors.sectionContent};
  `}
  margin-bottom: 24px;
  ${margins};
  ${paddings};
`

export default StyledParagraph
