import styled from "styled-components"

import Image from "gatsby-image"

const StyledImage = styled(Image)`
  cursor: pointer;
  ${({ theme }) => `
    transition: ${theme.transitions.image.hover};

    :hover {
      box-shadow: ${theme.customShadows.image.hover};
    }
  `}
`

export default StyledImage
