import React from "react"
import MQ from "react-responsive"
import PropTypes from "prop-types"
import { withTheme } from "styled-components"

const MOBILE = "mobile"
const DESKTOP = "desktop"

const MediaQuery = ({ children, mode, theme }) => {
  const { mobile } = theme.breakpoints

  return mode === DESKTOP ? (
    <MQ query={`(min-width: ${mobile + 1}px)`}>{children}</MQ>
  ) : (
    <MQ query={`(max-width: ${mobile}px)`}>{children}</MQ>
  )
}

MediaQuery.propTypes = {
  // Responsive mode.
  mode: PropTypes.oneOf([MOBILE, DESKTOP]),
}

export default withTheme(MediaQuery)
