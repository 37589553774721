import styled from "styled-components"

const Subtitle = styled.h2`
  ${({ theme }) => `
      color: ${theme.colors.title};
      font-size: ${theme.fontSizes.subtitle};
  `}
`

export default Subtitle
