import React, { PureComponent } from "react"
import { Link as GatsbyLink } from "gatsby"

import { GlobalContext } from "providers"

import { Link } from "styles"

import Paragraph from "./styles/Paragraph"

class Footer extends PureComponent {
  render() {
    return (
      <GlobalContext.Consumer>
        {({ contributors }) => (
          <Paragraph>
            Site web créé avec{" "}
            <Link dark href={contributors.componentsExtra}>
              components-extra
            </Link>{" "}
            par{" "}
            <Link dark href={contributors.alelain}>
              Alexandre Le Lain -
            </Link>
            <GatsbyLink to="/legal">- Mentions légales.</GatsbyLink>
          </Paragraph>
        )}
      </GlobalContext.Consumer>
    )
  }
}

export default Footer
