import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ description, keywords, lang, meta, title }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            url
            google
            image
            keywords
          }
        }
      }
    `
  )

  const metaData = site.siteMetadata

  const metaDescription = description || metaData.description
  const metaKeywords = [...metaData.keywords, ...keywords]
  const metaTitle = title ? `${title} | ${metaData.title}` : metaData.title
  const metaTemplate = title ? `%s | ${metaData.title}` : metaData.title

  return (
    <Helmet
      htmlAttributes={{ lang }}
      title={metaTitle}
      titleTemplate={metaTemplate}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: metaTitle,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:url`,
          content: metaData.url,
        },
        {
          property: `og:image`,
          content: metaData.image,
        },
        {
          property: `og:site_name`,
          content: metaTitle,
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:creator`,
          content: metaData.author,
        },
        {
          name: `twitter:title`,
          content: metaTitle,
        },
        {
          name: `twitter:url`,
          content: metaData.url,
        },
        {
          name: `twitter:site`,
          content: metaData.author,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `twitter:label1`,
          content: "Auteur",
        },
        {
          name: `twitter:label2`,
          content: "Catégories",
        },
        {
          name: `twitter:data1`,
          content: "Georges Paumard",
        },
        {
          name: `twitter:data2`,
          content: "Livre, Avenir, 2050",
        },
        {
          name: `google-site-verification`,
          content: metaData.google,
        },
      ]
        .concat(
          metaKeywords.length > 0
            ? {
                name: `keywords`,
                content: metaKeywords.join(`, `),
              }
            : []
        )
        .concat(meta)}
    >
      <link
        rel="stylesheet preconnect"
        href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"
      />
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `fr`,
  meta: [],
  keywords: [],
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string,
}

export default SEO
