import React from "react"
import { StaticQuery, graphql } from "gatsby"

import { GlobalContext } from "providers"

import StyledCritic from "../styles/StyledCritic"

const criticRender = data => {
  const { fluid } = data.placeholderImage.childImageSharp

  return (
    <GlobalContext.Consumer>
      {({ links }) => (
        <a
          title="Voir l'article"
          href={links.ouestFrance}
          target="_blank"
          rel="noreferrer noopener"
        >
          <StyledCritic fluid={fluid} />
        </a>
      )}
    </GlobalContext.Consumer>
  )
}

const OuestFrance = () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          placeholderImage: file(relativePath: { eq: "ouest_france.png" }) {
            childImageSharp {
              fluid(maxWidth: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      `}
      render={criticRender}
    />
  )
}

export default OuestFrance
