import styled from "styled-components"

import ImageContainer from "./ImageContainer"

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 24px auto;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  ${ImageContainer} {
    margin: 0px 25px;
  }
`

export default Container
