import React, { PureComponent } from "react"
import { StaticQuery, graphql } from "gatsby"

import { GlobalContext } from "providers"

import Container from "./styles/Container"
import ImageContainer from "./styles/ImageContainer"
import StyledImage from "./styles/StyledImage"
import StyledParagraph from "./styles/StyledParagraph"

import OuestFrance from "./components/OuestFrance"

class BookPreview extends PureComponent {
  bookRender = data => {
    const { fluid } = data.placeholderImage.childImageSharp

    return (
      <GlobalContext.Consumer>
        {({ links }) => (
          <ImageContainer>
            <a
              title="Commander le livre"
              href={links.amazon}
              target="_blank"
              rel="noreferrer noopener"
            >
              <StyledImage fluid={fluid} />
            </a>
          </ImageContainer>
        )}
      </GlobalContext.Consumer>
    )
  }

  render() {
    return (
      <Container>
        <StyledParagraph>
          <p>
            "Georges Paumard se promet de ne « jamais » mettre les doigts « dans
            la politique » mais il ne se défend pas d’évoquer le fruit de ses
            réflexions, tout haut…"
          </p>
        </StyledParagraph>
        <StaticQuery
          query={graphql`
            query {
              placeholderImage: file(relativePath: { eq: "book_preview.png" }) {
                childImageSharp {
                  fluid(maxWidth: 300) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          `}
          render={this.bookRender}
        />{" "}
        <StyledParagraph>
          <p>"...Et c’est finalement très stimulant pour tous"</p>
          <OuestFrance />
        </StyledParagraph>
      </Container>
    )
  }
}

export default BookPreview
