import React from "react"
import styled from "styled-components"
import { StaticQuery, graphql } from "gatsby"

import BackgroundImage from "gatsby-background-image"

const imageRender = ({ children, className, data }) => {
  const { fluid } = data.placeholderImage.childImageSharp

  return (
    <BackgroundImage className={className} Tag="section" fluid={fluid}>
      {children}
    </BackgroundImage>
  )
}

const Background = ({ children, className }) => (
  <StaticQuery
    query={graphql`
      query {
        placeholderImage: file(relativePath: { eq: "header.webp" }) {
          childImageSharp {
            fluid(maxWidth: 2000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => imageRender({ children, className, data })}
  />
)

const BackgroundCover = styled(Background)`
  width: 100%;
  height: 100%;
  background-repeat: repeat-y;
`

export default BackgroundCover
