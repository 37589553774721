import styled from "styled-components"
import Title from "./Title"

const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 80px;

  &:last-of-type {
    margin-bottom: 32px;
  }
  ${Title} {
    margin-bottom: 64px;
  }
  ${({ theme }) => `
    @media (max-width: ${theme.breakpoints.mobile}px) {
      margin-bottom: 50px;
    }
  `}
`

export default SectionContainer
