import React from "react"
import { Navbar } from "components-extra"

import Brand from "./components/Brand"

export default ({ hideMenu = false }) => {
  return (
    <Navbar position="sticky">
      <Navbar.Brand title="Avec Vous Demain" href="/">
        <Brand />
      </Navbar.Brand>
      {!hideMenu && (
        <Navbar.Menu>
          <Navbar.MenuItem href="#overview">Présentation</Navbar.MenuItem>
          <Navbar.MenuItem href="#samples">Thématiques</Navbar.MenuItem>
          <Navbar.MenuItem href="#about">À propos de l'auteur</Navbar.MenuItem>
          <Navbar.MenuItem href="#order">Commander le livre</Navbar.MenuItem>
        </Navbar.Menu>
      )}
    </Navbar>
  )
}
