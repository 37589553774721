const colors = {
  whitePrimary: "#e6e6e6",
  whiteSecondary: "#f2f2f2",
  cadetblue: "cadetblue",
  lightCadetblue: "#7eb1b3",
  darkPrimary: "#333333",
  darkSecondary: "#4d4d4d",
  darkTertiary: "#595959",
}

export default {
  palette: {
    primary: {
      main: "#5f9ea0",
    },
    secondary: {
      main: "#e6e6e6",
    },
  },
  colors: {
    title: colors.whitePrimary,
    subtitle: colors.whitePrimary,
    imageOverlay: "rgba(50,50,50,0.5)",
    navbar: colors.cadetblue,
    navButton: colors.lightCadetblue,
    navitem: colors.whitePrimary,
    navitemFocus: colors.darkPrimary,
    pageBackground: colors.whitePrimary,
    sectionTitle: colors.darkPrimary,
    sectionSubtitle: colors.darkSecondary,
    sectionContent: colors.darkTertiary,
    bookPreviewBackground: "#737373",
    ...colors,
  },
  customShadows: {
    wording: "1px 0 #888888",
    image: {
      default: "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(250,250,250,0.24)",
      hover: "0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(250,250,250,0.22)",
    },
    darkImage: {
      default: "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
      hover: "0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)",
    },
  },
  transitions: {
    image: {
      hover: "all 0.3s cubic-bezier(.25,.8,.25,1)",
    },
  },
  fontSizes: {
    title: "calc(12px + 2vh + 3.5vw)",
    subtitle: "calc(12px + 0.6vh + 0.8vw)",
    bigsubtitle: "calc(12px + 0.8vh + 1.4vw)",
    paragraph: "calc(12px + 0.5vh + 0.1vw)",
    footer: "calc(10px + 0.3vh + 0.2vw)",
  },
  breakpoints: {
    mobile: 780,
    tablet: 1080,
  },
}
