import styled from "styled-components"

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 24px;
  ${({ theme }) => `
    @media (max-width: ${theme.breakpoints.mobile}px) {
      flex-wrap: wrap;
      justify-content: center;
    }
  `}
`

export default Container
