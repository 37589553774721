import React, { PureComponent } from "react"
import { StaticQuery, graphql } from "gatsby"

import imageRender from "./ImageRender"

class Diploma extends PureComponent {
  render() {
    return (
      <StaticQuery
        query={graphql`
          query {
            placeholderImage: file(relativePath: { eq: "diploma.png" }) {
              childImageSharp {
                fluid(quality: 99, maxWidth: 400) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        `}
        render={imageRender}
      />
    )
  }
}

export default Diploma
