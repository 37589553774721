import Img from "gatsby-image"
import styled from "styled-components"

const StyledCritic = styled(Img)`
  width: 100px;
  margin 0 auto;
  border-radius: 3px;
  ${({ theme }) => `
    background-color: ${theme.colors.whitePrimary};
  `}
`

export default StyledCritic
