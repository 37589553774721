import React, { PureComponent } from "react"

import Section from "../components/Section"

import Container from "./styles/Container"
import { Paragraph } from "../styles"

import {
  Amazon,
  Chapitre,
  Decitre,
  Fnac,
  Lib,
  Libraires,
  Rakuten,
} from "./components"

class Order extends PureComponent {
  render() {
    return (
      <Section id="order" title="Commander le livre">
        <Container>
          <Amazon />
          <Fnac />
          <Chapitre />
          <Decitre />
          <Libraires />
          <Lib />
          <Rakuten />
        </Container>
        <Paragraph>
          <strong>
            <u>Mots-clés:</u> &nbsp;&nbsp;
          </strong>
          Avec Vous Demain, 2050, Georges Paumard, les clefs de votre avenir,
          Europe, Intelligence Artificielle, Orientation des talents, Afrique
        </Paragraph>
      </Section>
    )
  }
}

export default Order
