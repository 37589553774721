import styled from "styled-components"

const Subtitle = styled.h2`
  ${({ theme }) => `
    color: ${theme.colors.sectionSubtitle};
  `}
  margin-bottom: 30px;
`

export default Subtitle
