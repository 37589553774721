import styled from "styled-components"

const StyledParagraph = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: calc(12px + 0.2vh + 0.3vw);
  padding: 0px 70px;
  font-style: italic;

  ${({ isLeft, theme }) => `
    color: ${theme.colors.whitePrimary};
    ${isLeft ? `margin-right: auto;` : `margin-left: auto;`}

    @media (max-width: ${theme.breakpoints.mobile}px) {
      padding: 0px 30px;
      margin-left: auto;
      margin-right: auto;
    }

  `}
`

export default StyledParagraph
