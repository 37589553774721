import { css } from "styled-components"
import PropTypes from "prop-types"

const paddings = css`
  ${({ pt }) => pt && `padding-top: ${pt}px`};
  ${({ pr }) => pr && `padding-right: ${pr}px`};
  ${({ pb }) => pb && `padding-bottom: ${pb}px`};
  ${({ pl }) => pl && `padding-left: ${pl}px`};
`

paddings.propTypes = {
  pt: PropTypes.string,
  pr: PropTypes.string,
  pb: PropTypes.string,
  pl: PropTypes.string,
}

export default paddings
