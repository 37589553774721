import React from "react"
import Img from "gatsby-image"

import ImageContainer from "../styles/ImageContainer"

const imageRender = data => {
  const { fluid } = data.placeholderImage.childImageSharp

  return (
    <ImageContainer>
      <Img fluid={fluid} />
    </ImageContainer>
  )
}

export default imageRender
