import styled from "styled-components"

const ImageContainer = styled.div`
  width: 140px;
  ${({ theme }) => `
    @media (max-width: ${theme.breakpoints.mobile}px) {
      width: 80px;
    }
  `}
`

export default ImageContainer
