import React, { PureComponent, Fragment } from "react"
import PropTypes from "prop-types"

import { SectionContainer, Title } from "../styles"

class Section extends PureComponent {
  render() {
    const { children, id, title } = this.props

    return (
      <SectionContainer id={id}>
        <Title>{title}</Title>
        <Fragment>{children}</Fragment>
      </SectionContainer>
    )
  }
}

Section.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
}

export default Section
