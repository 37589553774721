import styled from "styled-components"

const Container = styled.div`
  display: flex;
  align-items: center;
  padding-top: 64px;
  padding-bottom: 64px;
  ${({ theme }) => `
    @media (max-width: ${theme.breakpoints.mobile}px) {
      flex-direction: column;
    }
  `}
`

export default Container
