import React, { PureComponent } from "react"

import Section from "../components/Section"

import Profile from "./components/Profile"

import Container from "./styles/Container"
import { Paragraph } from "../styles"

class About extends PureComponent {
  render() {
    return (
      <Section id="about" title="À propos de l'auteur">
        <Container>
          <Profile />
          <div>
            <Paragraph>
              <strong>Georges Paumard</strong> a gravi tous les échelons du
              monde professionnel: d'employé à P.D.G. d'entreprise. Il a été
              aussi créateur d'une école de vente à Nantes, administrateur
              pendant dix ans d'une association humanitaire, administrateur puis
              président d'une association de quartier.
            </Paragraph>
            <Paragraph>
              Une vie riche de challenges et d'investissements.
            </Paragraph>
            <Paragraph>
              Il est l'auteur d'un livre "Les quartiers d'avenir de La Baule",
              et de nombreuses études.
            </Paragraph>
          </div>
        </Container>
      </Section>
    )
  }
}

export default About
