import React, { PureComponent } from "react"
import { StaticQuery, graphql } from "gatsby"

import resellerRender from "./Reseller"

class Libraires extends PureComponent {
  render() {
    return (
      <StaticQuery
        query={graphql`
          query {
            placeholderImage: file(relativePath: { eq: "libraires.png" }) {
              childImageSharp {
                fluid(quality: 99, maxWidth: 300) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        `}
        render={data =>
          resellerRender("Sur Place Des Libraires", "libraires", data)
        }
      />
    )
  }
}

export default Libraires
