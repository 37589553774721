import React, { PureComponent, Fragment } from "react"
import { Link } from "gatsby"

import Section from "../components/Section"

import { Africa, Diploma, Europe, IA } from "./components"

import { Paragraph, SampleContainer, Subtitle } from "../styles"

const Sample = ({ children, isFirst = false, title }) => (
  <SampleContainer isFirst={isFirst}>
    <Subtitle>{title}</Subtitle>
    <Fragment>{children}</Fragment>
  </SampleContainer>
)

class Samples extends PureComponent {
  renderIA() {
    return (
      <Sample
        isFirst
        title="L'IA: l'Intelligence Artificielle, un danger ou une chance pour chacun d'entre nous?"
      >
        <Paragraph>
          On en parle de plus en plus aujourd'hui, mais qu'est ce que
          l'Intelligence Artificielle exactement, et quelles seront ses
          conséquences?
        </Paragraph>
        <Paragraph>
          L'Intelligence Artificielle, ou <strong>IA</strong>, est l'ensemble
          des théories et des techniques mises en oeuvre en vue de réaliser des
          machines capables de simuler l'intelligence humaine.
        </Paragraph>
        <IA />
        <Paragraph>
          Avec le développement très rapide de l'<strong>IA</strong>, à quoi
          ressemblera le travail, demain? Aurons-nous d'ailleurs encore un
          travail, et comment l'exercerons-nous?
        </Paragraph>
        <Paragraph>
          Découvrez des réponses à ces questions dans le livre{" "}
          <strong>"Avec Vous Demain"</strong>, de Georges Paumard, aux éditions
          Sydney Laurent. Un livre surprenant qui nous apporte de nouvelles
          perspectives sur notre avenir.
        </Paragraph>
      </Sample>
    )
  }

  renderImmigration() {
    return (
      <Sample title="L'immigration: l'Afrique et la France">
        <Paragraph>
          Le continent africain est en pleine expansion. Expansion démographique
          d'abord: sa population passera d'ici <strong>2050</strong> de{" "}
          <strong>1,25 milliard</strong> d'individus à{" "}
          <strong>2,5 milliards</strong>, dont environ 600 millions maîtrisant
          la langue française. Si, selon certaines hypothèses, quatre Africains
          sur mille parlant le français désirent venir en France, cela
          équivaudrait à <strong>2,4 millions</strong> de personnes. L'Algérie
          seule est passée de 11 millions d'habitants en 1960 à près de 40
          millions en 2015 et avoisinera les 56 millions en 2050.
        </Paragraph>
        <Paragraph>
          Alors que de son coté, la population européenne devrait se stabiliser
          à un palier de 750 millions d'habitants.
        </Paragraph>
        <Africa />
        <Paragraph>
          Mais l'Afrique a aussi des <strong>atouts incomparables:</strong> un
          soleil généreux, des espaces importants, une richesse du sous-sol
          sous-exploitée et surtout une jeunesse nombreuse et dynamique, qui a
          comprit que l'avenir est bien là, dans son pays d'origine. L'Eldorado
          n'est plus l'Europe ou l'Amérique.
        </Paragraph>
        <Paragraph>
          Pour aider l'Afrique à devenir autonome, il convient de renforcer
          l'unité africaine, d'aider à mettre en place, avec le Fond Monétaire
          International, une monnaie commune à toute l'Afrique...
        </Paragraph>
        <Paragraph>
          L'avenir de l'Afrique et les racines de l'immigration sont expliquées
          avec simplicité et clarté dans <strong>"Avec Vous Demain"</strong>, un
          livre étonnant qui vous fera redécouvrir d'un oeil neuf cette Afrique
          fascinante.
        </Paragraph>
      </Sample>
    )
  }

  renderOrientation() {
    return (
      <Sample title="Orientation: Une bonne orientation des étudiants est essentielle, et pourtant...">
        <Paragraph>
          <strong>2015, le gâchis des talents:</strong>
        </Paragraph>
        <ul>
          <li>
            <Paragraph>
              - <strong>46.5%</strong> des étudiants en université ne passent
              pas en 2ème année: ils doivent alors redoubler ou changer
              d'orientation.
            </Paragraph>
          </li>
          <li>
            <Paragraph>
              - <strong>26% des jeunes de 16 à 25 ans sont chômeurs</strong>,
              soit un quart d'entre eux.
            </Paragraph>
          </li>
          <li>
            <Paragraph>
              - <strong>420 000</strong> apprentis en France contre{" "}
              <strong>1 500 000</strong> en Allemagne: comment expliquer cet
              écart ?
            </Paragraph>
          </li>
        </ul>
        <Paragraph>
          Des surdiplômés ne trouvent pas de travail et à l'inverse, des
          entreprises cherchent vainement du personnel qualifié. Il n'y a pas
          d'adéquation entre l'offre et la demande. Un vrai gâchis!
        </Paragraph>
        <Diploma />
        <Paragraph>
          De bonnes mesures ont été mises en place au début de l'année 2018 -
          par exemple Parcoursup - mais qui s'avèrent insuffisantes. Il manque
          encore les conseils avisés et personnalisés de professionnels de
          l'orientation. Car dans un monde en évolution très rapide, il est très
          difficile pour un étudiant de 15 à 18 ans de se projeter dans
          l'avenir, dès lors que de très nombreux métiers peuvent être appelés à
          disparaître, et autant, sans doute, à se créer.
        </Paragraph>
        <Paragraph>
          <strong>« Vers le plein emploi des talents »</strong>
        </Paragraph>
        <Paragraph>
          Découvrez de nouvelles perspectives sur notre avenir avec le livre{" "}
          <strong>"Avec Vous Demain"</strong> de Georges Paumard, aux éditions
          Sydney Laurent.
          <Link to="/#order">{` `}Commander le livre ici.</Link>
        </Paragraph>
      </Sample>
    )
  }

  renderEurope() {
    return (
      <Sample title="L'Europe">
        <Paragraph>
          Après le Brexit, l'Europe doit réagir en harmonisant sa fiscalité, le
          taux de TVA, les impôts sur les sociétés et en renforçant ses
          contrôles aux frontières en matière de commerce et d'immigration.
        </Paragraph>
        <Paragraph>
          <strong>Elle doit aider et encourager l'innovation.</strong> Tous les
          membres de l'Union consacrent dorénavant 2% de leur P.I.B à la
          défense, dont un quart serait réservée à la défense commune. Toutes
          les décisions importantes doivent maintenant être prises à la double
          majorité de 75% des pays et 75% des habitants; et non plus à
          l'unanimité.
        </Paragraph>
        <Europe />
        <Paragraph>
          En effet, il suffit aujourd'hui qu'un ou deux pays placent leur
          intérêts personnels avant l'intérêt général pour que cela fasse bien
          sûr le jeu du Royaume-Uni, de la Chine, de la Russie ou des
          Etats-Unis, par exemple.
        </Paragraph>
        <Paragraph>
          <strong>
            L'Europe doit ainsi devenir plus cohérente et plus visible!
          </strong>
        </Paragraph>
      </Sample>
    )
  }

  render() {
    return (
      <Section id="samples" title="Quelques Thématiques abordées par le livre">
        <Fragment>
          {this.renderIA()}
          <hr />
          {this.renderImmigration()}
          <hr />
          {this.renderOrientation()}
          <hr />
          {this.renderEurope()}
        </Fragment>
      </Section>
    )
  }
}

export default Samples
