import styled from "styled-components"
import PropTypes from "prop-types"
import Subtitle from "./Subtitle"

const SampleContainer = styled.div`
  ${({ isFirst }) => `
    margin-top: ${isFirst ? "0px" : "64px"};
    margin-bottom: 64px;
    &:last-child {
      margin-bottom: 0px;
    }
  `}

  ${Subtitle} {
    margin-bottom: 64px;
    ${({ theme }) => `
      @media (max-width: ${theme.breakpoints.mobile}px) {
        margin-bottom: 32px;
      }
    `}
  }
`

SampleContainer.defaultProps = {
  isFirst: false,
}

SampleContainer.propTypes = {
  isFirst: PropTypes.bool,
}

export default SampleContainer
