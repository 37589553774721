import styled from "styled-components"

const Paragraph = styled.p`
  text-align: center;
  ${({ theme }) => `
    color: ${theme.colors.whiteSecondary};
    background-color: ${theme.colors.cadetblue};
    margin: 0;
    padding: 12px 8px;
    font-size: ${theme.fontSizes.footer};
  `}
`

export default Paragraph
