import React, { PureComponent } from "react"

import Section from "../components/Section"

import { Paragraph } from "../styles"

class Presentation extends PureComponent {
  render() {
    return (
      <Section id="overview" title="Présentation du livre">
        <Paragraph>
          Dans un monde en évolution rapide et constante sur les plans
          politique, économique, social et religieux, l'auteur souhaite que ce
          livre vous permette d'imaginer votre avenir, de vous y projeter et de
          vous y préparer.
        </Paragraph>
        <Paragraph>
          Pour amorcer ce bond dans le futur, vous allez vivre du 14 Mai au 31
          Août 2050 avec la famille Lagadec, résidant à La Baule en Bretagne
          Sud.
        </Paragraph>
        <Paragraph>
          Une partie de ce livre traite des problèmes de nos sociétés actuelles
          et futures, tandis que dans une autre partie sont proposées des idées
          d'adaptation et de développement de la Côte d'Amour.
        </Paragraph>
        <Paragraph>
          Bien sûr, ces idées peuvent être adaptées et transposées chez vous
          partout en France et ailleurs. Faisons confiance aux acteurs locaux et
          à la génération start-up de votre région!
        </Paragraph>
      </Section>
    )
  }
}

export default Presentation
