import styled from "styled-components"

const Title = styled.h1`
  margin 64px auto;
  ${({ theme }) => `
    * {
      color: ${theme.colors.title};
      font-size: ${theme.fontSizes.title};
      font-weight: bold;
      text-shadow: ${theme.customShadows.wording}
      letter-spacing: 1px;
    }
  `}
`

export default Title
