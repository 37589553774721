import styled from "styled-components"

const SectionsContainer = styled.div`
  padding: 62px 32px;
  ${({ theme }) => `
    background-color: ${theme.colors.pageBackground};
  `}
  hr {
    width: 100%;
  }
`

export default SectionsContainer
