import React from "react"

import { GlobalContext } from "providers"

import { Link } from "styles"

import ImageContainer from "../styles/ImageContainer"
import Legend from "../styles/Legend"
import StyledImage from "../styles/StyledImage"

const resellerRender = (title, href, data) => {
  const { fluid } = data.placeholderImage.childImageSharp

  return (
    <GlobalContext.Consumer>
      {({ links }) => (
        <ImageContainer>
          <Link
            title={title}
            href={links[href]}
            target="_blank"
            rel="noreferrer noopener"
          >
            <StyledImage fluid={fluid} />
            <Legend>{title}</Legend>
          </Link>
        </ImageContainer>
      )}
    </GlobalContext.Consumer>
  )
}

export default resellerRender
